import type { StyleRule } from '@archipro-design/aria';
import { buttonClassName } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem, flexClassName } from '@archipro-design/aria';
import { boxClassName } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const MainLinkColor: StyleRule = ({ theme }) => ({
    '& a': {
        color: theme.siteVariables.colorScheme.default?.foreground,
        ':hover': {
            color: theme.siteVariables.colors.charcoal['250'],
        },
    },
});

export const CustomEnquriyMessage: StyleRule = () => ({
    whiteSpace: 'pre-wrap',
    marginBottom: pxToRem(15),
});

export const MainInfoContainer: StyleRule = ({
    theme,
    hasBannerImage = true,
}) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    marginBottom: hasBannerImage ? 0 : pxToRem(108),
});

export const LeftSection: StyleRule = ({ theme }) => ({
    width: pxToRem(436),
    '& hr': {
        margin: `0 0 ${pxToRem(29)} 0`,
    },
    '& h3': {
        marginTop: 0,
        marginBottom: pxToRem(20),
        color: theme.siteVariables.colorScheme.default?.foreground,
    },
});
export const RightSection: StyleRule = ({ theme }) => ({
    width: pxToRem(896),

    [theme.screen.clamped]: {
        width: pxToRem(996),
    },
});

export const LabelText: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    fontSize: pxToRem(16),
    [theme.screen.laptop]: {
        fontSize: pxToRem(22),
        lineHeight: theme.siteVariables.lineHeightSmall,
    },
});

export const LabelNumber: StyleRule = () => ({
    '& a': {
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export const LineDivider: StyleRule = ({ theme }) => ({
    border: 0,
    borderBottom: `1px solid ${theme.siteVariables.colors.white[300]}`,
    margin: 0,
    [theme.screen.laptop]: {
        borderBottom: `1px solid ${theme.siteVariables.colors.primitive.black}`,
    },

    [`&:only-child`]: {
        display: 'none',
    },
});

export const SectionText: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    marginTop: pxToRem(46),
    marginBottom: pxToRem(28),
    letterSpacing: theme.siteVariables.letterSpacingMedium,

    ...MainLinkColor({ theme }),
    [theme.screen.laptop]: {
        fontSize: pxToRem(16),
        marginBottom: pxToRem(20),
        lineHeight: theme.siteVariables.lineHeightSmall,
    },
});

export const SectionServices: StyleRule = ({ theme }) => ({
    '& > ul': {
        listStyle: 'none',
        marginTop: pxToRem(46),
        marginBottom: pxToRem(18),
        padding: 0,
        '& > li': {
            ...SectionText({ theme }),
            marginTop: 0,
            marginBottom: 0,
            height: pxToRem(34),
        },
    },
});

export const MoreOrLessLink: StyleRule = ({ theme }) => ({
    ...LabelText({ theme }),
    textDecoration: 'underline',
    cursor: 'pointer',
});

export const PhoneSection: StyleRule = ({ theme }) => ({
    [`& .${flexClassName}`]: {
        margin: pxArrayToRem([15, 0, 16, 0]),
        [`& > .${boxClassName} > div:nth-child(2)`]: {
            marginTop: pxToRem(16),
        },

        [theme.screen.laptop]: {
            margin: 0,
        },
    },
});

export const ClickToRevealClass: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(8),
    [`& .${labelClassName}`]: {
        lineHeight: pxToRem(14),
        textDecoration: 'underline',
        textUnderlineOffset: pxToRem(2),
        fontSize: theme.siteVariables.fontSizes.caption04,
        letterSpacing: theme.siteVariables.letterSpacingNormal,
        [theme.screen.laptop]: {
            fontSize: pxToRem(16),
            lineHeight: theme.siteVariables.lineHeightSmall,
            letterSpacing: theme.siteVariables.letterSpacingMedium,
            fontWeight: theme.siteVariables.fontWeightRegular,
        },
    },
    [theme.screen.laptop]: {
        marginTop: 0,
    },

    [`&:hover .${labelClassName}`]: {
        color: theme.siteVariables.colorScheme.default?.foregroundHover,
    },
});

export const MobileInfoContainer: StyleRule = () => ({
    margin: pxArrayToRem([0, 18]),
    [`& > div:nth-child(2)`]: {
        '& hr:first-child': {
            display: 'none',
        },
    },
});

export const BusinessFocus: StyleRule = () => ({
    [`& .${flexClassName}`]: {
        margin: pxArrayToRem([12, 0, 16, 0]),
    },
});

export const DesignBoardGroup: StyleRule = ({ theme }) => ({
    marginRight: pxToRem(4),
    marginLeft: pxToRem(-7),

    ['& .' + boxClassName]: {
        marginRight: '0',
        '&:not(:last-child)': {
            marginRight: '0',
        },
        ['& .' + svgIconClassName]: {
            width: pxToRem(40),
            height: pxToRem(40),
        },
    },

    [theme.screen.min.tablet]: {
        display: 'block',
    },
});

export const HeartButton: StyleRule = ({ theme }) => ({
    display: 'none',
    borderRadius: '50%',
    [theme.screen.tablet]: {
        display: 'block',
    },
});

export const OverviewEnquiryFormContainer: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        marginTop: pxToRem(40),
    },
});

export const Contacts: StyleRule = ({ theme }) => ({
    gap: theme.siteVariables.spaces['space-8'],
    marginTop: pxToRem(13),
    marginBottom: pxToRem(50),
});

export const EnquireContainer: StyleRule = {
    position: 'relative',
};

export const EnquireButton: StyleRule = {
    width: '100%',
    height: pxToRem(48),
    textTransform: 'uppercase',
};

export const SaveToDesignBoardContainer: StyleRule = {
    position: 'absolute',
    right: 0,
    top: 0,
    height: pxToRem(48),
};

export const ViewWebsiteButton: StyleRule = ({ theme }) => ({
    borderColor: theme.siteVariables.colors.charcoal['000'],
    width: '100%',
});

export const ResposeRate: StyleRule = () => ({
    marginTop: pxToRem(7),
});

export const EnquiryTitle: StyleRule = () => ({
    textTransform: 'uppercase',
    marginBottom: pxToRem(15),
});

export const LinkButton: StyleRule = () => ({
    [`&.${buttonClassName} `]: {
        textDecoration: 'underline',
    },
    '& > span': {
        marginRight: pxToRem(10),
    },
});

export const SaveShareContainer: StyleRule = () => ({
    paddingTop: 0,
});

export const ButtonHeart: StyleRule = ({ theme }) => ({
    width: pxToRem(48),
    height: pxToRem(48),
    [`&.${buttonClassName}`]: {
        border: `${pxToRem(1)} solid ${
            theme.siteVariables.colors.charcoal['250']
        }`,
        [`&:active`]: {
            border: `${pxToRem(1)} solid ${
                theme.siteVariables.colors.charcoal['250']
            }`,
        },
    },
});
